<template>
  <div>
    <b-button
      v-if="!noBtn"
      variant="success"
      @click="toggleModal()"
    >
      <feather-icon
        icon="PlusIcon"
      />
      {{ $t('buttons.add') }}
    </b-button>
    <b-modal
      v-model="isWhitelistModalActive"
      title="Whitelist"
      hide-footer
    >
      <b-overlay :show="isFetching">
        <form @submit.prevent="onSubmit">
          <b-form-group
            v-if="roleName === 'OWNER'"
            label-cols-sm="12"
            label-cols-lg="3"
            label="เลือก Master"
            label-for="selectedMasterId"
          >
            <b-select v-model="selectedMasterId">
              <b-select-option
                v-for="(item, index) of masterList"
                :key="index"
                :value="item.id"
              >
                {{ item.name }}
              </b-select-option>
            </b-select>
          </b-form-group>
          <b-form-group
            v-if="roleName !== 'AGENT'"
            label-cols-sm="12"
            label-cols-lg="3"
            label="เลือก Agent"
            label-for="agentId"
          >
            <b-select
              id="agentId"
              v-model="whitelistForm.agentId"
            >
              <b-form-select-option
                v-for="(item, index) in agentList"
                :key="index"
                :value="item.id"
              >
                {{ item.name }}
              </b-form-select-option>
            </b-select>
          </b-form-group>
          <b-form-group
            label-cols-sm="12"
            label-cols-lg="3"
            label="IP"
            label-for="value"
          >
            <b-form-textarea
              id="value"
              v-model="whitelistForm.ip"
            />
          </b-form-group>

          <div class="text-right">
            <b-button
              variant="light"
              class="mr-2"
              @click="onCloseModal"
            >
              {{ $t('buttons.cancel') }}
            </b-button>
            <b-overlay
              :show="isLoading"
              rounded
              spinner-small
              class="d-inline-block"
            >
              <b-button
                type="submit"
                variant="primary"
                block
              >
                {{ $t('buttons.confirm') }}
              </b-button>
            </b-overlay>
          </div>
        </form>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  props: {
    id: {
      type: String,
      default: '',
    },
    noBtn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isWhitelistModalActive: false,
      whitelistForm: {
        ip: '',
        agentId: null,
      },
      selectedMasterId: null,
    }
  },
  computed: {
    ...mapState({
      isFetching: (state) => state.blacklist.isFetchingBlacklist,
      isLoading: (state) =>
        state.blacklist.isAddingBlacklist ||
        state.blacklist.isUpdatingBlacklist,
      userInfo: (state) => state.user.userInfo,
    }),
    masterList() {
      return this.userInfo.masters
    },
    agentList() {
      if (!this.selectedMasterId) {
        return []
      }
      return (
        this.userInfo.masters.find(
          (master) => master.id === this.selectedMasterId
        ).children || []
      )
    },
    roleName() {
      return this.userInfo.roleName || null
    },
  },
  watch: {
    id(val) {
      if (val) {
        this.fetchData()
      }
    },
  },
  created() {
    this.fetchData()
    if (this.userInfo.roleName === 'MASTER') {
      this.selectedMasterId = this.userInfo.agentId
    }
  },
  methods: {
    ...mapActions(['fetchBlacklist', 'createWhitelist', 'updateBlacklist']),
    fetchData() {
      if (this.id) {
        this.fetchBlacklist(this.id)
      }
    },
    toggleModal() {
      this.isWhitelistModalActive = !this.isWhitelistModalActive
    },
    onCloseModal() {
      this.isWhitelistModalActive = false
    },
    async onSubmit() {
      await this.createWhitelist(this.whitelistForm)
      this.onCloseModal()
      this.whitelistForm = {
        ip: '',
        agentId: null,
      }
    },
  },
}
</script>
